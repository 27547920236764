import $ from 'jquery';

import AuthContext from 'chairisher/context/auth';
import BackToTop from 'chairisher/component/backtotop';
import BaseController from 'chairisher/_controller';
import BusinessContext from 'chairisher/context/business';
import ImageCarousel from 'chairisher/component/imagecarousel';
import MediaQueryUtils, { prefersReducedMotion } from 'chairisher/util/mediaquery';
import ProductContext from 'chairisher/context/product';
import RepeatingScroller from 'chairisher/component/scroller/repeating';
import SiteContext from 'chairisher/context/site';
import TradeRewardsDashboardView from 'chairisher/view/trade/rewards/dashboard';
import UriUtils from 'chairisher/util/uri';

import bindInlineEmailSignup from 'chairisher/view/promo/inlineemailsignup';
import createProductRail from 'chairisher/view/helper/rail';
import getCarouselStories from 'chairisher/context/carousel';
import trackHomepageScreen from 'chairisher/analytics/homepage';

import { trackDesignerBusinessImpressions } from 'chairisher/analytics/business/search';
import { trackSuccessfulEmailPanelCollection } from 'chairisher/analytics/email';
import { getPromotedListingsUrl } from 'chairisher/context/promotedlistings';
import { logAmplitudeEvent, ProductRailPosition, ProductRailType, UhpPosition } from 'chairisher/util/analytics';
import { observeIntersectionOnce } from 'chairisher/view/helper/intersectionobserver';
import { CountUp } from 'countup';

/**
 * Controller that binds actions for the UHP
 */
class HomepageController extends BaseController {
    /** @inheritDoc * */
    bindPageAnalytics() {
        super.bindPageAnalytics();
        trackHomepageScreen();
    }

    /** @inheritDoc * */
    bindChairishActions() {
        super.bindChairishActions();

        const carouselStories = getCarouselStories();
        if (carouselStories.length > 0) {
            new ImageCarousel({
                elClass: 'js-hero-story-carousel',
                externalData: carouselStories,
                position: UhpPosition.HERO,
            }).bind();
        }

        const shouldAutoScroll = MediaQueryUtils.isMinMediumDesktop();

        // new arrivals scroller
        const $container = $('#js-new-arrival-grid');
        if ($container.length) {
            new RepeatingScroller({
                analyticsTrackingType: ProductRailType.NEW_ARRIVALS,
                container: $container.get(0),
                children: $container.children('.product').get(),
                shouldAutoScroll,
            });
        }

        // add recently viewed above fold rail
        const rvAboveFoldProductsIdSelector = '#js-recently-viewed-above-fold';
        createProductRail({
            $containerEl: $(rvAboveFoldProductsIdSelector),
            $el: $(`${rvAboveFoldProductsIdSelector}-scrollable-grid`),
            analyticsTrackingPosition: ProductRailPosition.TOP,
            analyticsTrackingType: ProductRailType.RECENTLY_VIEWED,
            listSelector: rvAboveFoldProductsIdSelector,
            railUrl: ProductContext.getRecentlyViewedUrl(),
        });

        const $wholesaleContainer = $('#js-wholesale-featured-grid');
        if ($wholesaleContainer.length) {
            new RepeatingScroller({
                analyticsTrackingType: ProductRailType.WHOLESALE,
                container: $wholesaleContainer.get(0),
                children: $wholesaleContainer.children('.product').get(),
                shouldAutoScroll,
            });
        }

        bindInlineEmailSignup({
            captureType: 'panel',
            formSelector: '#js-chairish-inline-email-collector-form',
            emailTrackingCallback() {
                trackSuccessfulEmailPanelCollection();
            },
        });

        if (AuthContext.isTrade()) {
            new TradeRewardsDashboardView($('.js-trade-rewards-dashboard'));
        }

        observeIntersectionOnce('.js-uhp-designer-search', () => {
            $.ajax({
                url: BusinessContext.getBusinessRailEndpoint(),
            }).done((response) => {
                $('.js-uhp-designer-search .js-color-block-inner').html(response.html);
                BusinessContext.setBusinessRailData(response.business_json);
                $.each(BusinessContext.getBusinessRailData(), (_, businessData) => {
                    trackDesignerBusinessImpressions(
                        UhpPosition.DESIGNER_DIRECTORY,
                        businessData.is_dma_match,
                        businessData.absolute_url,
                    );
                });
            });
        });

        if (!prefersReducedMotion()) {
            const countTargetId = 'js-sustainability-kpi';
            observeIntersectionOnce(`#${countTargetId}`, () => {
                const countUp = new CountUp(countTargetId, SiteContext.getSustainabilityKpi());
                countUp.start();
            });
        }

        if (UriUtils.extractParamFromUri(document.location.search, 'lead-created')) {
            const $modal = $($('#js-business-application-confirmation-modal').html());
            $(document.body).append($modal);
            $modal.modal('show');

            logAmplitudeEvent('business register - success');

            $modal.on('click', '.js-close', () => {
                $modal.modal('hide');
            });
        }

        this.bindFeaturedNewArrivalsProducts();

        BackToTop.bind();
    }

    bindFeaturedNewArrivalsProducts() {
        const featuredTopProductsIdSelector = '#js-featured-new-arrivals-products-top-rail';
        createProductRail({
            $containerEl: $(featuredTopProductsIdSelector),
            $el: $(`${featuredTopProductsIdSelector}-scrollable-grid`),
            analyticsTrackingPosition: ProductRailPosition.TOP,
            analyticsTrackingType: ProductRailType.NEW_ARRIVALS,
            listSelector: featuredTopProductsIdSelector,
            railUrl: getPromotedListingsUrl(),
            shouldShowDetails: false,
        });

        const featuredBottomProductsIdSelector = '#js-featured-new-arrivals-products-bottom-rail';
        createProductRail({
            $containerEl: $(featuredBottomProductsIdSelector),
            $el: $(`${featuredBottomProductsIdSelector}-scrollable-grid`),
            analyticsTrackingPosition: ProductRailPosition.BOTTOM,
            analyticsTrackingType: ProductRailType.NEW_ARRIVALS,
            listSelector: featuredBottomProductsIdSelector,
            railUrl: getPromotedListingsUrl(),
            shouldShowDetails: false,
        });
    }
}

export default HomepageController;
